import React from 'react';
import { FiMenu } from 'react-icons/fi';
import { IconButton, Flex, HStack, FlexProps, Image } from '@chakra-ui/react';
import logoSvg from 'assets/svg/logo.svg';
import { CommuniqueCentral } from '../CommuniqueCentral';
import { HeaderMenu } from '../Menu';
import { NotificationCentral } from '../NotificationCentral';

interface HeaderProps extends FlexProps {
  onOpen: () => void;
}

export const Header = ({ onOpen, ...rest }: HeaderProps) => {
  return (
    <Flex
      zIndex={9}
      w="100%"
      position="fixed"
      ml={{ base: 0, xl: 0 }}
      px={4}
      height="60px"
      alignItems="center"
      bg="mutable.100"
      justifyContent="space-between"
      py="4px"
      {...rest}
    >
      <IconButton
        data-cy="menu"
        color="white"
        display={{ base: 'flex', xl: 'none' }}
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu size="18px" />}
      />

      <Image
        alt=""
        display={{ base: 'flex', xl: 'none' }}
        mx="16px"
        src={logoSvg}
        h="40px"
      />

      <HStack display="flex" spacing={6} ml={{ base: '0', xl: 'auto' }}>
        <CommuniqueCentral />
        <NotificationCentral />
        <HeaderMenu />
      </HStack>
    </Flex>
  );
};
