import React, { useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Box,
  ModalHeader,
  Flex,
  Text,
  ModalCloseButton,
} from '@chakra-ui/react';
import { RenderAttachments } from '../attachements';

interface FgtPwModalProps {
  isOpen: boolean;
  onClose: () => void;
  file: string;
  files?: CommuniqueFile[];
}

export const PDFmodal = ({ isOpen, onClose, file, files }: FgtPwModalProps) => {
  const PdfViewer = useCallback(() => {
    return (
      <embed src={file} type="application/pdf" width="100%" height="100%" />
    );
  }, [file]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderTopRadius="8px"
        h="94%"
        mb="0px"
        minW={{ base: '100%', md: '80%' }}
        bg="white"
      >
        <ModalHeader minH="32px">
          <ModalCloseButton color="black" />
        </ModalHeader>
        <ModalBody py="24px" pb="24px" mb="0px" textAlign="center">
          <PdfViewer />
        </ModalBody>

        <ModalFooter justifyContent="center" pt="0px" mt="20px" pb="24px">
          <Flex w="100%" justifyContent="space-between">
            <Box>
              <Text color="text.100">Anexos</Text>
              <RenderAttachments files={files} />
            </Box>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
