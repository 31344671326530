import React from 'react';
import { BsFileEarmarkRichtext } from 'react-icons/bs';
import { MdOutlineImage } from 'react-icons/md';
import { Flex, Icon, Text, Link } from '@chakra-ui/react';

const getFileIcon = (fileName: string) => {
  const extension = fileName.split('.').pop()?.toLowerCase();
  if (['jpg', 'jpeg', 'png', 'gif'].includes(extension || '')) {
    return MdOutlineImage;
  }
  return BsFileEarmarkRichtext;
};

export const RenderAttachments = ({ files }: { files?: CommuniqueFile[] }) => {
  return (
    <>
      {files?.map(attachment => (
        <Link
          key={attachment.name}
          href={attachment.src}
          download={attachment.name}
          target="_blank"
        >
          <Flex alignItems="center" gap="8px">
            <Icon
              w="18px"
              h="18px"
              as={getFileIcon(attachment.name)}
              color="blue.0"
            />
            <Text fontSize="14px" color="blue.0">
              {attachment.name}
            </Text>
          </Flex>
        </Link>
      ))}
    </>
  );
};
