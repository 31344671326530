import React, { useCallback, useMemo } from 'react';
import { MdOutlineCampaign } from 'react-icons/md';
import {
  Icon,
  Flex,
  DrawerContent,
  DrawerHeader,
  Drawer,
  DrawerBody,
  VStack,
  DrawerCloseButton,
  DrawerOverlay,
  useDisclosure,
  Center,
  Image,
  Text,
  Spinner,
} from '@chakra-ui/react';
import {
  dateFormatter,
  setCommuniqueIDs,
  useAppDispatch,
  useAppSelector,
  useGetBaseComuniquesQuery,
} from '@spartalabs/pdc-core';
import { EmptyNot } from 'assets';
import { ErrorCard } from 'components/Cards/ErrorCard';
import { CommuniqueItem } from 'components/CommuniqueItem';
import { Loading } from 'components/Loading';

export const CommuniqueCentral: React.FC = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { communiqueIDs } = useAppSelector(state => state.globalDataSlicer);

  const dispatch = useAppDispatch();

  const { data, isError, isLoading, refetch } = useGetBaseComuniquesQuery();

  const { idArray = [], isEqual = false } = useMemo(() => {
    const idArray = data?.flatMap(item => item.id) || [];
    const isEqual = idArray.every(id => communiqueIDs.includes(id));

    return {
      idArray,
      isEqual,
    };
  }, [communiqueIDs, data]);

  const handleOpen = useCallback(() => {
    if (!isEqual) dispatch(setCommuniqueIDs(idArray || []));

    onOpen();
  }, [idArray, isEqual]);

  return (
    <>
      <Flex onClick={handleOpen} cursor="pointer">
        <Center
          mt={isLoading ? '2px' : '-4px'}
          ml="20px"
          position="absolute"
          w="8px"
          h={data && isEqual ? '0px' : '8px'}
          bg={isLoading ? 'ocean.200' : 'red'}
          borderRadius="full"
        >
          {isLoading && (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="grey.200"
              color="ocean.200"
              size="xs"
              position="absolute"
              zIndex={1}
              alignSelf="center"
            />
          )}
        </Center>

        <Icon as={MdOutlineCampaign} color="white" w="24px" h="24px" />
      </Flex>

      <Drawer size="sm" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent boxShadow="none" bg="transparent">
          <DrawerCloseButton />
          <DrawerHeader
            py="18px"
            bg="mutable.100"
            fontSize="16px"
            ml="74px"
            w="370px"
          >
            O que há de novo no Portal?
          </DrawerHeader>

          <DrawerBody
            position="relative"
            overflowY="visible"
            ml="74px"
            w="370px"
            bg="white"
          >
            {isLoading ? (
              <Center h="100%">
                <Loading />
              </Center>
            ) : isError ? (
              <Center h="100%">
                <ErrorCard refetch={refetch} border="none" shadow="none" />
              </Center>
            ) : data && data?.length < 1 ? (
              <Center h="90%" flexDir="column">
                <Image alt="remover logo" src={EmptyNot} />
                <Text
                  w="180px"
                  mt="20px"
                  textAlign="center"
                  mb="80px"
                  textColor="text.500"
                >
                  Você não possui novos comunicados.
                </Text>
              </Center>
            ) : (
              <VStack gap="16px">
                {data?.map(item => (
                  <CommuniqueItem
                    id={item.id || ''}
                    title={item.title}
                    desc={item.description}
                    cat={item.category}
                    key={item.id}
                    thumb={item.coverImage?.src || ''}
                    redirectFile={item.redirectAttachment?.src || ''}
                    startDate={dateFormatter(item.startDate)}
                    redirect={item.redirect}
                    files={item.attachments}
                    link={item.link}
                  />
                ))}
              </VStack>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
